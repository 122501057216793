import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";

export default function TrangQuaySo() {
    const { user } = useSelector((state) => state.auth);
    const [listCode, setListCode] = useState([])
    const [reload, setReload] = useState(false)
    const [start, setStart] = useState(false)
    const [counter, setCouter] = useState(0)
    const [codeActive, setCodeActice] = useState(null)
    const [userLucky, setUserLucky] = useState(null)
    const [luckyInfo, setLuckyInfo] = useState(null)

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "code_vote?status=1", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListCode(res.data.data)
            } else console.log(res.data.message);
        })
    }, [userLucky])

    useEffect(() => {
        if (userLucky) {
            Axios.get(URL_LIST_ALL + "code_vote?code=" + userLucky, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setLuckyInfo(res.data.data[0])
                } else console.log(res.data.message);
            })
        }
    }, [userLucky])

    useEffect(() => {
        if (start && listCode && listCode.length > 0) {
            runtime()
        } else {
            setCouter(0)
        }
    }, [reload, start])

    const runtime = () => {
        setTimeout(() => {
            let xCou = counter + 100
            setCouter(xCou)
            let index = Math.floor(Math.random() * listCode.length)
            setCodeActice(listCode[index].code);
            if (xCou >= 10000) {
                setUserLucky(listCode[index].code)
                setStart(false)
            } else {
                setReload(!reload)
            }
        }, 100)
    }

    const updateCode = () => {
        if (luckyInfo) {
            let id = luckyInfo.id
            Axios.put(URL_LIST_ALL + 'code_vote/' + id, { status:2 }, user.config).then(res => {
                if (res.data.status === "success") {
                    setCodeActice(null)
                    setUserLucky(null)
                    setLuckyInfo(null)
                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <div className="w-100 home-page page-quayso">
            <div className="w-100 d-flex vh-100">
                {luckyInfo ?
                    <div className="w-100 pt-5 thong-bao">
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <div className="box-lucky">
                                <img className="img-lucky" alt="" src="/img/box-lucky.png" />
                                <span>{luckyInfo.code}</span>
                            </div>
                        </div>
                        <div className="w-100 text-center text-thongbao mt-3">
                            <span>Chúc mừng {luckyInfo.fullname} {luckyInfo.part} {luckyInfo.user_code}</span>
                            <small>Đã trở thành</small>
                            <span>Chiến sĩ may mắn nhất</span>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
                            <button className="button-start" type="button" onClick={() => {
                                updateCode()

                            }}
                            >
                                <img className="card-img-top" alt="" src="/img/bt-start.png" />
                                <span>Quay tiếp</span>
                            </button>
                        </div>
                    </div>
                    :
                    <div className="w-100">
                        <div className="w-100">
                            <img className="card-img-top" alt="" src="/img/quayso-start.png" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-7">
                                    <img className="card-img-top" alt="" src="/img/quay-so-title.png" />
                                </div>
                                <div className="col-5 d-flex justify-content-center align-items-center">
                                    <button className="button-start" type="button" onClick={() => {
                                        setStart(!start)
                                        setCodeActice(null)
                                        setUserLucky(null)
                                        setLuckyInfo(null)
                                    }}
                                    >
                                        <img className="card-img-top" alt="" src="/img/bt-start.png" />
                                        <span>{codeActive ? codeActive : "Bắt đầu"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                }



            </div>

        </div >
    );
}
