import React from "react";
import ReactDOM from "react-dom";

//THeme Akkhor
import './assets/css/normalize.css'
import './assets/css/main.css'
import './assets/css/bootstrap.min.css'
import './assets/css/all.min.css'
import './assets/fonts/flaticon.css'
import './assets/css/select2.min.css'
import './assets/css/fullcalendar.min.css'
import './assets/css/animate.min.css'
import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/datepicker.min.css';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
//END THeme Akkhor
import "./index.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import mockAxios from "./mocks";

import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";


// require("dotenv").config();
var axios = require("axios");
const { REACT_APP_USE_MOCK_DATA } = process.env;
if (REACT_APP_USE_MOCK_DATA === "true") mockAxios(axios);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
