import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Modal } from "react-bootstrap";
import { actions } from "../redux/authRedux";

export default function TrangChu() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [listTeam, setListTeam] = useState(null);
    const [listPoint, setListPoint] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [modalDieule, setModalDieule] = useState(false)
    const [pointVote, setPointVote] = useState(0)
    const [teamVote, setTeamVote] = useState('')
    const [message, setMessage] = useState('')
    const [teamId, setTeamId] = useState(null)
    const [listCode, setListCode] = useState([])
    const [totalVote, setTotalVote] = useState(0)
    const [settingVote, setSettingVote] = useState(0)
    const [userVoted, setUserVoted] = useState(null)

    let client = null;

    const connect = () => {
        if (client === null) {
            client = new W3CWebSocket('wss://ws-ap1.pusher.com:443/app/121162a077743bac91f8?client=js&version=7.0.3&protocol=5');
            client.onopen = () => client.send(JSON.stringify({ event: "pusher:subscribe", data: { auth: "", channel: "bhsvote" } }));
            client.onerror = (e) => console.log('Connection Error: ', e);
            client.onclose = () => {
                setTimeout(function () {
                    client = null;
                    connect();
                }, 1000);
            }
            client.onmessage = (e) => {
                try {
                    const rawData = JSON.parse(e.data);
                    if (rawData && (rawData.event === 'user_' + user.data.code || rawData.event === 'user_0')) {
                        receive()
                    }
                } catch (e) {
                    console.log('socket error: ', e.message())
                }
            };
        }
    }

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "settings/25", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setSettingVote(parseInt(res.data.data.value))
                console.log(res.data.data.value);
            } else console.log(res.data.message);
        })
    }, [])

    useEffect(() => {
        connect();
    }, [])

    const receive = () => {
        Axios.get(URL_LIST_ALL + "load_team_point", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListPoint(res.data.data)
            } else console.log(res.data.message);
        })
    };

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "team_vote", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListTeam(res.data.data)
            } else console.log(res.data.message);
        })
    }, [listPoint])

    const actionVote = () => {
        setModalShow(false)
        Axios.post(URL_LIST_ALL + 'users_vote', { team_id: teamId, value: pointVote }, user.config).then(res => {
            if (res.data.status === "success") {
                setMessage(res.data.message)
                setListCode(res.data.datavote.listcode)
                setTotalVote(res.data.datavote.totalvote)
                setModalSuccess(true)
            } else {
                setModalSuccess(true)
            }
        }).catch(err => console.log(err));
    }

    const actionSaveReaded = () => {
        user.dieule = 1
        dispatch(actions.login(user))
        setModalDieule(false)
    }

    useEffect(() => {
        if (!user.dieule) {
            setModalDieule(true)
        }
    }, [user])
    useEffect(() => {
        Axios.get(URL_LIST_ALL + "users_vote?user_id=" + user.data.id, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setUserVoted(res.data)
            } else console.log(res.data.message);
        })
    }, [user])
    return (
        <div className="w-100 trangchu home-page pb-5">
            <div className="w-100">
                <img className="card-img-top" alt="" src="/img/title.png" />
            </div>
            <div className="container">
                <div className="row">
                    {settingVote ? <>
                        {listTeam && listTeam.map(function (item, i) {
                            return (
                                <div className="col-12 item-team mt-4" key={i}>
                                    <img className="card-img-top" alt="" src={'/img/' + 'team' + item.id + '.png'} />
                                    <div className="w-100 mt-3">
                                        <Formik
                                            initialValues={{ value: '' }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setPointVote(values.value)
                                                setTeamVote(item.title)
                                                setTeamId(item.id)
                                                setModalShow(true)
                                                setSubmitting(false)
                                            }}
                                        >
                                            {({
                                                values,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="w-100 d-flex justify-content-between group-action-vote">
                                                        <img className="bg-input" alt="" src="/img/formvote.png" />
                                                        <input type="number" className="input-vote" name="value" placeholder="Số lượng vote" value={values.value} onChange={handleChange} onBlur={handleBlur} />
                                                        <button type="submit" className="bt-vote" disabled={isSubmitting}>
                                                            <img src="img/btvote.png" alt="" />
                                                        </button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                        :
                        <div className="w-100">
                            <div className="group-ketthuc mt-5">
                                <img className="card-img-top" alt="" src="/img/bt-start.png" />
                                <span>Trò chơi kết thúc</span>
                            </div>
                            <div className="w-100">
                                <div className="list-codes end-listcode mt-3">
                                    {userVoted && <>Tổng số đã vote: <span>{userVoted.totalvote}</span><br /></>}

                                    {userVoted && userVoted.list_code && userVoted.list_code.length > 0 && <div className="w-100 mt-2">Danh sách mã bốc thăm trúng thưởng của anh chị là:<br />{userVoted.list_code.map(function (item, i) { return (<span key={i}>{item}, </span>) })}</div>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >

            <Modal show={modalShow} onHide={() => setModalShow(false)} animation={true} size="md" className="modal-thongbao">
                <Modal.Body>
                    <div className="body-modal">
                        <img src="/img/popup-thongbao.png" className="w-100" alt="" />
                        <div className="content-thongbao">
                            XÁC NHẬN VOTE <span style={{color:"#f7a800"}}>{pointVote}</span> VOTE <br /> CHO ĐỘI {teamVote}
                            <div className="w-100 d-flex justify-content-around">
                                <button className="button-success" onClick={() => actionVote()}>
                                    <img src="/img/button-success.png" alt="" />
                                </button>
                                <button className="button-cancle" onClick={() => setModalShow(false)}>
                                    <img src="/img/button-cancle.png" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modalSuccess} onHide={() => setModalSuccess(false)} animation={true} size="md" className="modal-thanhcong">
                <Modal.Body>
                    <div className="body-modal-thanhcong">
                        <img src="/img/text-success.png" className="w-100" alt="" />
                        <div className="content-thanhcong" >
                            {message}<br />
                            <div className="list-codes mt-3">
                                {totalVote && <>Tổng số vote đã thực hiện: <span>{totalVote}</span><br /></>}

                                {listCode && listCode.length > 0 && <div className="w-100 mt-2">Danh sách mã bốc thăm trúng thưởng của anh chị là:<br />{listCode.map(function (item, i) { return (<span key={i}>{item}, </span>) })}</div>}
                            </div>
                        </div>

                        <button className="button-cancle" onClick={() => setModalSuccess(false)}>
                            <img src="/img/button-close.png" alt="" />
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modalDieule} onHide={() => setModalDieule(false)} animation={true} size="md" className="modal-thanhcong">
                <Modal.Body>
                    <div className="body-modal-dieule">
                        <img src="/img/dieule.png" />
                        <button className="button-cancle" onClick={() => { actionSaveReaded() }}>
                            <img src="/img/button-close.png" alt="" />
                        </button>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="button-dongy" onClick={() => { actionSaveReaded() }}>
                                <img className="card-img-top" alt="" src="/img/bt-start.png" />
                                <span>Đồng ý</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}
