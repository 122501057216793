import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import AuthPage from "./auth";
import Loading from "./component/Loading";
import { actions } from "./redux/authRedux";

import Axios from "axios";
import { URL_LIST_ALL } from "./configs/api";

import TrangChu from "./homepage/TrangChu";
import Dashboard from "./homepage/Dashboard";
import TrangQuaySo from "./homepage/TrangQuaySo";

function App(props) {
  const { user } = useSelector((state) => state.auth);
  const isAuthorized = user != null;
  const now = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;
  const dispatch = useDispatch();
  // const crurl = window.location.href
  
  useEffect(function () {
    
  if (isAuthorized && !user.data.roles) dispatch(actions.login(null));
  }, [dispatch, user, isAuthorized])

  useEffect(function () {
    if (isAuthorized && user) {
      Axios.get(URL_LIST_ALL + "users_login?user_id=" + user.data.id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (res.data.data[0].expert && res.data.data[0].expert === 1) dispatch(actions.login(null));
          } else {
            if (res.data.code === 'login') {
              window.location.href = '/auth/login'
            } else {
              console.log(res.data.message);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user, dispatch, isAuthorized])

  return (
    <BrowserRouter basename={""}>
      <Suspense fallback={<Loading color="secondary" />} >
        <Switch>
          <Route path="/auth">
            <AuthPage></AuthPage>
          </Route>
          {!isAuthorized ?
            //console.log(encodeURI(crurl))
            <Redirect to="/auth/login" />
            :
            <>
              {user.expires > now ? <>
                <Route exact path="/"><TrangChu /></Route>
                <Route exact path="/dashboard"><Dashboard /></Route>
                <Route exact path="/quay-so"><TrangQuaySo /></Route>
              </> : <Loading color="secondary" expire={user.expires <= now} />}
            </>
          }
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
