import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  TimeTableSave: null,
  user: null,
  Answers: {},
  year_id: null,
  listyear: null,
};
export const slice = createSlice({
  name: "save",
  initialState: initialState,
  reducers: {
    choice: (state, action) => {
      if (action.payload.hasOwnProperty("TimeTableSave"))
        state.TimeTableSave = action.payload.TimeTableSave;
      if (action.payload.hasOwnProperty("user"))
        state.user = action.payload.user;
      if (action.payload.hasOwnProperty("Answers"))
        state.Answers = action.payload.Answers;
      if (action.payload.hasOwnProperty("year_id"))
        state.year_id = action.payload.year_id;
      if (action.payload.hasOwnProperty("listyear"))
        state.listyear = action.payload.listyear;
    },
  },
});
