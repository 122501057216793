import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action"
};

const initialAuthState = {
  user: undefined,
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { user } = action.payload;
        return {user };
      }

      case actionTypes.Register: {
        const { user } = action.payload;

        return {user };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: user => ({ type: actionTypes.Login, payload: { user } }),
  register: user => ({ type: actionTypes.Register, payload: { user } }),
  logout: () => ({ type: actionTypes.Logout }),
};

