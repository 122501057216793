import React from "react";

export default function AlertErrorForm(props) {
  const { formik, name } = props;
  return (
    <>
      {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
      ) : null}
    </>
  );
}
