import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";


export default function Dashboard() {
    const { user } = useSelector((state) => state.auth);
    const [listTeam, setListTeam] = useState([]);
    const [listPoint, setListPoint] = useState(null)
    const [settingVote, setSettingVote] = useState(0)

    let client = null;

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "settings/25", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setSettingVote(parseInt(res.data.data.value))
            } else console.log(res.data.message);
        })
    }, [])
    const connect = () => {
        if (client === null) {
            client = new W3CWebSocket('wss://ws-ap1.pusher.com:443/app/121162a077743bac91f8?client=js&version=7.0.3&protocol=5');
            client.onopen = () => client.send(JSON.stringify({ event: "pusher:subscribe", data: { auth: "", channel: "bhsvote" } }));
            client.onerror = (e) => console.log('Connection Error: ', e);
            client.onclose = () => {
                setTimeout(function () {
                    client = null;
                    connect();
                }, 1000);
            }
            client.onmessage = (e) => {
                try {
                    const rawData = JSON.parse(e.data);
                    if (rawData && (rawData.event === 'user_' + user.data.code || rawData.event === 'user_0')) {
                        receive()
                    }
                } catch (e) {
                    console.log('socket error: ', e.message())
                }
            };
        }
    }

    useEffect(() => {
        receive()
        connect();
    }, [])

    const receive = () => {
        Axios.get(URL_LIST_ALL + "load_team_point", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListPoint(res.data.data)
            } else console.log(res.data.message);
        })
    };

    useEffect(() => {
        Axios.get(URL_LIST_ALL + "team_vote", user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListTeam(res.data.data)
            } else console.log(res.data.message);
        })
    }, [])

    const actionEndVote = () => {
        Axios.put(URL_LIST_ALL + "settings/25",{value:0}, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setSettingVote(0)
            } else console.log(res.data.message);
        })
    }
    return (
        <div className="w-100 home-page page-dashboard">
            <div className="w-100 d-flex justify-content-center">
                <img src="/img/title-dashboard.png" alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 list-team mt-2">
                        {listTeam && listTeam.length > 1 && <>
                            {listTeam.map(function (item, i) {
                                return (<div className={listPoint ? "col-12 col-sm-3 mt-3 item-team top-" + listPoint[item.id].level : "col-12 col-sm-3 mt-3"} key={i}>
                                    <div className="item-point d-flex justify-content-center mb-2">
                                        <h5>{listPoint ? listPoint[item.id].point : item.totalpoint}</h5>
                                    </div>
                                    <img className="card-img-top" alt="" src={'/img/' + 'team' + item.id + '.png'} alt=""/>
                                </div>)
                            })}
                        </>}
                    </div>
                </div>
                
            </div>
            <button className="button-ketthuc" type="button" onClick={()=>actionEndVote()}>
                <img className="card-img-top" alt="" src="/img/bt-start.png" />
                <span>{settingVote && settingVote === 1 ? "Kết thúc" : "Đã kết thúc"}</span>
            </button>
        </div >
    );
}
