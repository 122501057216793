import React from 'react';
import { Redirect, Route,Switch } from "react-router-dom";
import Login from './Login';
import Logout from './Logout';

export default function AuthPage() {
 
  return (
    <div>
     <Switch>
        <Redirect exact={true}   from="/auth" to="/auth/login" />
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/logout" component={Logout} />
    </Switch>
    </div>
  );
}
